import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth.js";
import college from "@/store/modules/college.js";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    loading: false,
  };
};

const state = getDefaultState();

const modules = {
  auth,
  college,
};

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],

  state,

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },

  actions: {
    async resetAppState({ commit }) {
      commit("RESET_STATE");
    },
  },

  modules,
});

export default store;

<template>
  <div class="max-w-12xl mx-auto text-left md:h-full">
    <div class="md:h-full">
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:h-full"
      >
        <div class="college-search flex flex-col">
          <header class="bg-white py-6 px-6">
            <h1 class="text-3xl font-extrabold mb-1">Course Finder</h1>
            <div class="text-sm">
              Find any course at a California two-year college.
            </div>
            <div class="text-xs mt-1">
              <em
                >Sample only. Full courses and catalogs are available with
                subscription</em
              >
            </div>
          </header>

          <div class="bg-white sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6 sm:py-3">
              <div class="flex flex-row justify-between">
                <h2 class="text-2xl leading-6 font-medium text-green-800 mb-1">
                  {{
                    selectedCollegeName
                      ? "Selected College"
                      : "Search for a College"
                  }}
                </h2>
                <div>
                  <button
                    class="btn border border-green-400 bg-green-300 text-gray-500 shadow-md px-4 rounded-lg"
                    @click="resetSearch"
                  >
                    reset
                  </button>
                </div>
              </div>
              <div
                v-if="!selectedCollegeName"
                class="mt-2 max-w-xl text-sm text-gray-500"
              >
                <p>Enter the first few characters of the college name</p>
              </div>
              <div
                v-if="!selectedCollegeName"
                class="relative mt-5 sm:flex sm:items-center"
              >
                <input
                  type="text"
                  placeholder="Search for a college"
                  class="input-text"
                  v-model="query"
                  @input="filterColleges"
                  @focus="toggleDropdown(true)"
                  @blur="toggleDropdown(false)"
                />
                <div
                  v-if="showDropdown"
                  class="absolute z-10 bg-white top-5 mt-5 w-full border border-gray-200 rounded shadow-lg"
                >
                  <div
                    v-for="college in filteredColleges"
                    :key="college.id"
                    class="cursor-pointer hover:bg-gray-200 p-2"
                    @click="
                      setInstitutionId(college.id, college.institutionName)
                    "
                  >
                    {{ college.institutionName }}
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <div
                  v-if="selectedCollegeName"
                  class="mt-3 text-gray-800 text-sm font-bold"
                >
                  {{ selectedCollegeName }}
                </div>
              </div>
            </div>

            <transition name="fade">
              <div
                class="px-4 py-5 sm:px-6 sm:py-3 border-t-1"
                v-if="catalogs.length >= 1"
              >
                <h2 class="text-2xl leading-6 font-medium text-green-800 mb-3">
                  College Year
                </h2>
                <select v-model="catalogId" class="input-text mt-3">
                  <option value="" disabled>Select a catalog</option>
                  <option
                    v-for="catalog in sortedCatalogs"
                    :key="catalog.id"
                    :value="catalog.id"
                  >
                    {{ catalog.edition }}
                  </option>
                </select>
              </div>
            </transition>

            <transition name="fade">
              <div
                class="px-4 py-5 sm:px-6 sm:py-3"
                v-if="catalogs.length >= 1"
              >
                <h2 class="text-2xl leading-6 font-medium text-green-800 mb-3">
                  Find a course
                </h2>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Search by subject code, number or search term.</p>
                </div>
                <div class="relative mt-3 sm:flex sm:items-center">
                  <input
                    type="text"
                    placeholder="Search for a course"
                    class="input-text"
                    v-model="courseName"
                    @input="filterCourses"
                    @focus="toggleCourseDropdown(true)"
                    @blur="toggleCourseDropdown(false)"
                  />
                  <div
                    v-if="showCourseDropdown"
                    class="absolute z-10 bg-white top-10 mt-5 w-full border border-gray-200 rounded shadow-lg"
                  >
                    <div
                      v-for="course in filteredCourses"
                      :key="course.id"
                      class="cursor-pointer hover:bg-gray-200 p-2"
                      @click="setCourseId(course.id)"
                    >
                      {{ course.courseNumber }} - {{ course.title }}
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>

          <footer
            class="mt-auto bg-white py-8 px-6 border-t-1 border-solid border-indigo-500 flex flex-col md:flex-row"
          >
            <a href="https://degreedata.com" target="_blank">
              <img
                src="https://degreedata.com/wp-content/uploads/2020/12/degreedata_logo.png"
                alt="DegreeData Logo"
                class="w-40 flex-none mb-3"
              />
            </a>
            <div class="flex-auto md:text-right text-sm">
              &copy; 2023 DegreeData, LLC
            </div>
          </footer>
        </div>

        <div class="course-details lg:col-span-2 relative px-6 py-6">
          <transition name="fade">
            <div class="sticky top-5 mt-0" v-if="course">
              <div class="bg-white shadow-inner sm:rounded-lg">
                <div class="px-4 py-5 sm:p-6">
                  <h3
                    class="text-2xl leading-6 font-medium text-green-800 mb-3"
                  >
                    {{ course.courseNumber }}
                  </h3>
                  <div class="mt-3">
                    <div class="text-gray-900 text-lg">
                      {{ course.title }}
                    </div>
                  </div>

                  <div class="mb-2">
                    <div class="text-gray-500">
                      {{ course.description }}
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="text-gray-800">Prerequisites</div>
                    <div class="text-gray-500" v-if="course.corequisites >= 1">
                      {{ course.prerequisites }}
                    </div>
                    <div class="text-gray-500" v-if="course.corequisites < 1">
                      None
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="text-gray-800">Corequisites</div>
                    <div class="text-gray-500" v-if="course.corequisites >= 1">
                      {{ course.corequisites }}
                    </div>
                    <div class="text-gray-500" v-if="course.corequisites < 1">
                      None
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="text-gray-800">CIP</div>
                    <div class="text-gray-500" v-if="course.cip >= 1">
                      {{ course.cip }}
                    </div>
                    <div class="text-gray-500" v-if="course.cip < 1">None</div>
                  </div>

                  <div class="mt-4">
                    <div class="text-gray-800">Credits</div>
                    <div class="text-gray-500">
                      {{ course.creditsUnitsHours }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sticky top-5 mt-0" v-else>
              <div class="bg-white shadow-inner sm:rounded-lg">
                <div class="px-4 py-5 sm:p-6 opacity-30">
                  <h2
                    class="text-2xl leading-6 font-medium text-green-800 mb-3"
                  >
                    Course Details
                  </h2>
                  <p>Your selected course will appear here...</p>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CourseFinder",

  data() {
    return {
      query: "",
      courseName: "",
      showCourseDropdown: false,
      filteredCourses: [],
      institutionId: "",
      catalogId: "",
      courseId: "",
      showDropdown: false,
      filteredColleges: [],
      selectedCollegeName: "",
    };
  },

  computed: {
    ...mapGetters({
      allColleges: "college/getAllColleges",
      catalogs: "college/getAllCatalogs",
      allCourses: "college/getAllCourses",
    }),

    colleges() {
      if (this.allColleges.length && this.query) {
        return this.allColleges.filter(({ institutionName }) =>
          institutionName.toLowerCase().includes(this.query.toLowerCase())
        );
      } else {
        return [];
      }
    },

    courses() {
      if (this.allCourses.length && this.courseName) {
        return this.allCourses.filter(({ courseNumber, title }) => {
          const courseSearch = this.courseName.toLowerCase().trim();
          return (
            courseNumber.toLowerCase().includes(courseSearch) ||
            title.toLowerCase().includes(courseSearch)
          );
        });
      } else {
        return [];
      }
    },

    sortedCatalogs() {
      return this.catalogs.slice().sort((a, b) => {
        const startYearA = parseInt(a.edition.split("-")[0]);
        const startYearB = parseInt(b.edition.split("-")[0]);

        return startYearB - startYearA;
      });
    },

    course() {
      if (this.courseId) {
        return this.allCourses.find(({ id }) => this.courseId === id);
      }
      return null;
    },
  },

  watch: {
    institutionId(institutionId) {
      this.setCatalogId();
      this.$store.dispatch("college/resetCatalogs");
      if (institutionId) {
        this.$store.dispatch("college/fetchCatalogsByCollege", institutionId);
      }
    },
    catalogId(catalogId) {
      this.courseName = "";
      this.setCourseId();
      this.$store.dispatch("college/resetCourses");
      if (catalogId) {
        this.$store.dispatch("college/fetchCoursesByCatalog", catalogId);
      }
    },
  },

  created() {
    this.$store.dispatch("college/resetCollegeState");

    this.$store.dispatch("auth/loginCourseAPI", {
      username: process.env.VUE_APP_SPEAKER_API_LOGIN,
      email: process.env.VUE_APP_SPEAKER_API_EMAIL,
      password: process.env.VUE_APP_SPEAKER_API_PASSWORD,
    });

    this.$store.dispatch("college/fetchAllColleges");
  },

  methods: {
    resetSearch() {
      this.$store.dispatch("college/resetCollegeState");
      this.query = "";
      this.courseName = "";
      this.setInstitutionId();
    },

    setInstitutionId(institutionId = "", institutionName = "") {
      this.institutionId = institutionId;
      this.selectedCollegeName = institutionName;
      this.showDropdown = false; // Hide the dropdown after selection
    },

    setCatalogId(catalogId = "") {
      this.catalogId = catalogId;
    },

    setCourseId(courseId = "") {
      this.courseId = courseId;
    },

    filterColleges() {
      this.filteredColleges = this.allColleges
        .filter((college) =>
          college.institutionName
            .toLowerCase()
            .includes(this.query.toLowerCase())
        )
        .slice(0, 10); // Limit the results to 10 or as per your requirement
    },
    toggleDropdown(status) {
      setTimeout(() => {
        this.showDropdown = status;
      }, 300); // Delay added to handle blur event
    },

    filterCourses() {
      this.filteredCourses = this.courses.slice(0, 10); // Limit the results to 10 or as per your requirement
    },
    toggleCourseDropdown(status) {
      setTimeout(() => {
        this.showCourseDropdown = status;
      }, 300); // Delay added to handle blur event
    },
  },
};
</script>

<style>
#app {
  height: calc(100vh);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

h1 {
  font-family: "Inter var", sans-serif;
}
</style>

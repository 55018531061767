import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import CollegeService from "@/services/CollegeService";
import exclusions from "@/store/modules/exclusions";

Vue.use(Vuex);

const plugins = [
  createPersistedState({
    storage: window.localStorage,
  }),
];

const getDefaultState = () => {
  return {
    colleges: {},
    catalogs: {},
    courses: {},
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },

  SET_ALL_COLLEGES(state, data) {
    state["colleges"] = data;
  },

  SET_ALL_CATALOGS(state, data) {
    state["catalogs"] = data;
  },

  SET_ALL_COURSES(state, data) {
    state["courses"] = data;
  },
};

const actions = {
  async fetchAllColleges({ commit }) {
    commit("SET_LOADING", true, { root: true });
    await CollegeService.getAllColleges().then((response) => {
      commit(
        "SET_ALL_COLLEGES",
        response.data.filter(
          ({ ipedsId }) => !exclusions.colleges.includes(ipedsId)
        )
      );
      commit("SET_LOADING", false, { root: true });
    });
  },

  async fetchCatalogsByCollege({ commit }, id) {
    commit("SET_LOADING", true, { root: true });
    await CollegeService.getCatalogsByCollege(id).then((response) => {
      commit(
        "SET_ALL_CATALOGS",
        response.data.filter(({ id }) => !exclusions.catalogs.includes(id))
      );
      commit("SET_LOADING", false, { root: true });
    });
  },

  async fetchCoursesByCatalog({ commit }, catalogId) {
    commit("SET_LOADING", true, { root: true });
    await CollegeService.getCoursesByCatalog(catalogId).then((response) => {
      commit(
        "SET_ALL_COURSES",
        response.data.filter(({ id }) => !exclusions.courses.includes(id))
      );
      commit("SET_LOADING", false, { root: true });
    });
  },

  async resetCollegeState({ commit }) {
    actions.resetCatalogs({ commit });
    actions.resetCourses({ commit });
  },

  async resetCatalogs({ commit }) {
    commit("SET_ALL_CATALOGS", getDefaultState().catalogs);
  },

  async resetCourses({ commit }) {
    commit("SET_ALL_COURSES", getDefaultState().courses);
  },
};

const getters = {
  getAllColleges: (state) => state.colleges,
  getAllCatalogs: (state) => state.catalogs,
  getAllCourses: (state) => state.courses,
};

export default {
  namespaced: true,
  plugins,
  state,
  mutations,
  getters,
  actions,
};

import apiClient from "@/plugins/axios.js";

export default {
  async getAllColleges() {
    return await apiClient.get(
      "/institutions/state/level?state=CA&level=2-year"
    );
  },

  async getCatalogsByCollege(institutionid) {
    return await apiClient.get("/catalogs/withcourses", {
      params: { institutionid },
    });
  },

  async getCoursesByCatalog(id) {
    return await apiClient.get("/courses/catalog", { params: { id } });
  },
};

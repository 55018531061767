export default {
  /** institution.ipedsId[] */
  colleges: [
    442930, // Antelope Valley
    109350, // Antelope Valley College District
    110219, // Bryan University
    111638, // Casa Loma College - Los Angeles
    112385, // Coastline Community College
    122791, // College of San Mateo
    123217, // College of the Sequoias
    112561, // Columbia College
    395362, // Copper Mountain
    113218, // Cuyamaca
    114716, // Foothill-Deanza
    114859, // Fullertton
    117724, // Los Angeles Trade Technical College
    118541, // Marymount California University
    118684, // Mendocino
    118930, // Mission College
    118976, // Modesto Junior College
    119137, // Moorpark
    119331, // Napa Valley
    120290, // Ohlone
    120421, // Oxnard College
    120953, // Palo Verde
    123527, // San Bernardino Valley College
    122339, // San Diego City
    122384, // San Diego Miramar
    122834, // Santa Barbara Business College
    122889, // Santa Barbara City College
    399212, // Santiago Canyon College
    446561, // Stanbridge University
    442930, // University of Antelope Valley
    125462, // West Hills College-Coalinga
  ],

  /** catalog.id[] */
  catalogs: [],

  /** catalog.id[] */
  courses: [],
};

import apiClient from "@/plugins/axios.js";

export default {
  async login(payload) {
    return await apiClient.post("/login", {
      email: payload.email,
      username: payload.username,
      password: payload.password,
    });
  },
};

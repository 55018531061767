import axios from "axios";
import store from "@/store";
import { TokenService } from "@/services/StorageService";

let config = {
  baseURL:
    process.env.VUE_APP_API_URL !== undefined
      ? process.env.VUE_APP_API_URL
      : "//api.test/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 10000,
  // withCredentials: true, // Check cross-site Access-Control
};

const apiClient = axios.create(config);

apiClient.interceptors.request.use(
  function (config) {
    store.commit("SET_LOADING", true);
    let token = localStorage.getItem("course_finder_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${TokenService.getToken()}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiClient;

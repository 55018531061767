import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import LoginService from "@/services/LoginService";

Vue.use(Vuex);

const plugins = [
  createPersistedState({
    storage: window.localStorage,
  }),
];

const getDefaultState = () => {
  return {
    speakerToken: null,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },

  SET_SPEAKER_TOKEN(state, data) {
    state["speakerToken"] = data;
  },
};

const actions = {
  async loginCourseAPI({ commit }, payload) {
    // console.log(payload);
    let res = [];

    await LoginService.login(payload).then((response) => {
      // console.log(response);
      res = response.data;
      const token = response.data;
      localStorage.setItem("course_finder_token", token);
      localStorage.setItem("course_finder_login", true);
      commit("SET_SPEAKER_TOKEN", token);
    });

    return res;
  },

  async resetAuthState({ commit }) {
    commit("RESET_STATE");
  },
};

const getters = {};

export default {
  namespaced: true,
  plugins,
  state,
  mutations,
  getters,
  actions,
};
